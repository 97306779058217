.gigs-page {
    color: lightgrey;
    height: auto;
    display: block;
    width: 100%;
}
.gigs-list {
    color: rgb(33, 27, 27);
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    h2 {
        background-color: #b8a7b8;
        padding: 10px;
        text-transform: uppercase;
        font-weight: bolder;
    }
}

.gigs-container {
    padding: 0px; 
    box-sizing: border-box; 
    color: lightgrey
}   

.gig-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 10px;
    flex-wrap: nowrap;
}

.gig-details {
    display: flex;
    flex: 1;
    min-width: 0;
    text-align: left;
}

.gig-details p { 
    margin: 0 10px 0 0; /* Adjust the gap between paragraphs */ 
    white-space: nowrap; /* Prevent wrapping to keep items on one line */ 
    overflow: hidden; 
    text-overflow: ellipsis; /* Add ellipsis if content overflows */ 
    font-size: 18px;
}

.gig-links {
    display: flex;
    gap: 20px;
    flex-shrink: 0;
    font-size: 18px;
}

.gig-buttons {
    flex-shrink: 0;
    font-size: 12px;
    margin-top: 10px;
}

button { 
    padding: 10px 15px; cursor: pointer; 
    background-color: darkblue; 
    color: white; 
    border: none; 
    border-radius: 5px; 
    transition: background-color 0.3s; 
    white-space: nowrap; /* Ensure buttons do not break into multiple lines */ 
    a {
        color: white; 
        text-decoration: none; 
    }
} 

button:hover { 
    background-color: #0056b3; 
}


/* Media query for smaller screens */
@media (max-width: 768px) {

    .gigs-container {
        width: 100%; 
        max-width: 1024px; 
        box-sizing: border-box; 
        padding: 0px;
        
    }
    .gig-item {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .gig-details {
        flex-direction: column;        
        gap: 10px;
    }

    .gig-links {
        margin-top: 10px;
        margin-left: 0px;
    }
    .gig-buttons {
        margin-top: 10px;
        text-align: right;
    }
}
