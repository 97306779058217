body {
  background-color: black;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.App-logo {
  min-width: 300px;
  max-width: 900px;
  width: 95%;
  height: auto;
  pointer-events: none;
  margin: 0 auto; /* Center the image horizontally */
  display: block;
}

.App-header {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 0;
}

header, footer {
  flex: 0 0 auto;
}

.page {
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 60px;
}

.menuLink {
  color: lightgray;
  text-decoration:none;
  padding-right: 3vh;
}

.menuLink:hover {
  color: gray;
}

.App-link {
  color: gray;
}

.menu {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #001529;
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
}

.icons {
  display: flex;
  gap: 30px;
  font-size: 20px;
  padding-left: 20px;
  a {
    color: gray;
    text-decoration:none
  }
  a:hover {
    color: lightgray;
  }
}

.footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px;
  width: 100%;
  bottom:0;
  box-sizing: border-box;
  background-color: #001529;
  z-index: 1000;
}
  
.copyright {
  text-align: right;
  color: white;
  font-size: 14px;
  flex-shrink: 0;
  padding-right: 20px;
  
}

.container {
  position: relative;
  height: fit-content;
  overflow-y: auto;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./img/The\ Antimatter\ Fantasy.png');
  background-size: cover;
  opacity: 0.0;
  pointer-events: none;
}

.content {
  position: relative;
  z-index: 1;
  background-color: black;
  height: 100vh;

}

@media (max-width: 512px) {
  .App-logo {
    min-width: 200px;
  }
  .menuLink {
    padding-right: 1vh;
  }
  .footer { 
    flex-direction: column; 
    align-items: center; 
  } 
  .icons {
    padding-left: 0;

  }
  .copyright { 
    text-align: center;
  } 

  .page {
    padding-bottom: 80px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: center;
  }
  .icons {
    padding-left: 0;
  }
}


@media (max-width: 768px) and (min-width: 512px) { 
  .menuLink { 
    padding-right: 2vh; 
  }

  .footer { 
    flex-direction: column; 
    align-items: center; 
  } 
  .icons {
    padding-left: 0;

  }
  .copyright { 
    text-align: center;
  } 
  .page {
    padding-bottom: 80px;
  }
}
