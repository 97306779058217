.bandPage {
    width: auto;
    height: auto;
    color: lightgrey;
    text-align: center;
    img {
        width: 100%;
        min-width: 300px;
        height:auto;
        pointer-events: none;
    }
}
.history {
    text-align: left;
    margin: 0 auto;
    font-size: 18px;
    padding: 10px;
}
