.albumPage {
    height: auto;
    color: lightgrey;
    display: flex;
    align-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
}

.albumLeft {
    padding-top: 20px;
    padding-right: 20px;
    img {
        width: auto;
        height: 300px; 
    }
}

.albumInfo {
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.albumStory {
    display: block;
}

@media (max-width: 768px) { 
    .albumPage {
        flex-direction: column;
        margin: 0 auto;
    }

    .albumLeft {
        padding-right: 0px;
        text-align: center;

        img {
            width: auto;
            height: 300px;
        }
    }
}
  