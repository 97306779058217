.homePage {
    width: auto;
    height: auto;
    color: lightgrey;
    img {        
        min-width: 300px;
        height:auto;
        width: 100%;
    }
}
