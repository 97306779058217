.memberPage {
    width: auto;
    color: lightgrey;
    margin: 0 auto;
    
}

.memberPicture {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        height: auto;
        width: 80%;
    }
}

.memberInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
}
