.contactPage {
    display: block;
    width: auto;
    height: auto;
    color: lightgrey;
    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 18px;
    }   
}
